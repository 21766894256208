
import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'
import { getProvinceList, getCityList, getCountyList, getTownshipList, getFarmerInfoList } from '@/api/peasantInfoManagement'
import { selectRoles, selectRoleUsers, userBeAssessorList, creditAssessorAdd, creditAssessorEdit, creditAssessorDetail, creditBeAssessorDetail, creditBeAssessorDel, creditBeAssessorAdd } from '@/api/creditRatingManagement'
import { loadingText } from '@/utils/defaultGoalConfig'
@Component({
  components: {
    Pagination
  }
})
// 1-评定 2-未评
export default class Assessor extends Vue {
  private loadingTxt: string = loadingText
  private disabled: boolean = false
  private isRoleSearch: boolean = true
  private oldRoleSearch: string = ''
  private roleList: any = []
  private isPersonSearch: boolean = true
  private oldPersonSearch: string = ''
  private personList: any = []
  private formInfo: any = {
    role: '',
    roleId: '',
    person: '',
    pid: '',
    phone: ''
  }
  private rules: any = {
    role: { required: true, message: '请选择人员角色', trigger: 'change' },
    person: { required: true, message: '请选择评定人', trigger: 'change' }
  }

  private show: boolean = false
  private tableData: any = []
  private provinceOptions: any = {} // 省份集
  private cityOptions: any = {} // 城市集
  private countyOptions: any = {} // 区集
  private townshipOptions: any = {} // 街集
  private requestEnd: boolean = true
  // 查询表单
  private area: any = {
    province: '',
    city: '',
    county: '',
    township: '',
    villageName: '',
    name: ''
  }
  // 分页数据
  private paginationParam: any = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  private loading: boolean = false
  private loading2: boolean = false
  private loading3: boolean = false

  // 要添加的人员
  private row: any = []
  private addData: any = []
  private page: any = {
    pageIndex: 1,
    pageSize: 10,
    total: 0
  }
  // 是否搜索当前筛选条件
  private infoStatus: boolean = false
  // 当前页人员
  // get AssessorData(): any {
  get AssessorData(): any {
    if (this.$route.params.id != 'add') {
      return this.addData
    } else {
      let data: any = []
      if (this.addData.length < this.page.pageIndex * 10) {
        data = this.addData.slice((this.page.pageIndex - 1) * 10)
      } else {
        data = this.addData.slice((this.page.pageIndex - 1) * 10, this.page.pageIndex * 10)
      }
      return data
    }
  }

  created() {
    if (this.$route.params.id != 'add') {
      this.disabled = true
      this.getDetail(true)
    }
  }

  getAssessorData() {
    if (this.$route.params.id != 'add') {
      this.loading = true
      let params = {
        id: this.$route.params.id,
        pageNumber: this.page.pageIndex,
        pageSize: this.page.pageSize
      }
      creditBeAssessorDetail(params)
        .then((res) => {
          this.addData = res.data.data
          this.page.total = res.data.total
        })
        .finally(() => {
          this.loading = false
        })
    }
    // this.AssessorData()
  }

  // 详情
  async getDetail(isLoading: boolean = false) {
    isLoading ? (this.loading3 = true) : (this.loading = true)
    await creditBeAssessorDetail({ id: this.$route.params.id }).then((res) => {
      this.addData = res.data.data
      this.page.total = res.data.total
    })
    if (!isLoading) {
      this.loading = false
      return
    }
    await creditAssessorDetail({ pid: this.$route.params.userId }).then((res) => {
      let data = res.data.data
      this.formInfo.role = data.roleName
      this.formInfo.person = data.name
      this.formInfo.phone = data.phone
      this.formInfo.pid = data.pid
    })
    isLoading && (this.loading3 = false)
  }

  // 搜索人员，角色
  private querySearchAsync(queryStr: any, cb: any) {
    if (!this.isRoleSearch && this.oldRoleSearch == queryStr) {
      cb(this.roleList)
      return
    } else {
      this.oldRoleSearch = queryStr
    }
    if (this.isRoleSearch) {
      this.isRoleSearch = false
    }
    selectRoles({ roleName: queryStr }).then((res) => {
      // let data = []
      let data: any = res.data.data.map((item: any) => {
        return {
          value: item.name,
          id: item.id
        }
      })
      this.roleList = data
      cb(data)
    })
  }
  // 存角色id
  private handleManager(item: any) {
    this.formInfo.roleId = item.id
  }
  // 搜索人员，评定人
  private querySearchAsync2(queryStr: any, cb: any) {
    if (!this.formInfo.role) {
      cb([])
      return
    }
    // if (!this.isPersonSearch && this.oldPersonSearch == queryStr) {
    //   cb(this.personList)
    //   return
    // } else {
    //   this.oldPersonSearch = queryStr
    // }
    // if (this.isPersonSearch) {
    //   this.isPersonSearch = false
    // }
    let data = {
      name: queryStr,
      roleId: this.formInfo.roleId
    }
    if (!data.name) {
      delete data.name
    }
    selectRoleUsers(data).then((res) => {
      // let data = []
      let data: any = res.data.data.map((item: any) => {
        return {
          value: item.name,
          phone: item.phone,
          pid: item.pid
        }
      })
      this.personList = data
      cb(data)
    })
  }
  // 显示电话
  private handleManager2(item: any) {
    this.formInfo.phone = item.phone
    if (this.formInfo.pid && this.formInfo.pid != item.pid) {
      this.addData = []
    }
    this.formInfo.pid = item.pid
  }
  // 添加评定人
  private handleAdd() {
    if (!this.formInfo.pid) {
      return this.$message.error('请先选择评定人')
    }
    this.show = true
    // 获取省
    getProvinceList().then((res) => {
      if (res.data.code == 1000) {
        this.provinceOptions = res.data.data
      }
    })
    // 表格数据
    this.getList()
  }
  // 选择省
  private selecttedProvince(value: any) {
    this.infoStatus = false
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.cityOptions = []
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.city = ''
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCityList({ provinceCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.cityOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  // 选择城市
  private selecttedCity(value: any) {
    this.infoStatus = false
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.countyOptions = []
      this.townshipOptions = []
      this.formInfo.county = ''
      this.formInfo.township = ''
      getCountyList({ cityCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.countyOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  // 选择区
  private selecttedCounty(value: any) {
    this.infoStatus = false
    if (value && this.requestEnd) {
      this.requestEnd = false
      this.townshipOptions = []
      this.formInfo.township = ''
      getTownshipList({ areaCode: value.split(',')[0] })
        .then((res) => {
          if (res.data.code == 1000) {
            this.townshipOptions = res.data.data
          }
        })
        .finally(() => {
          this.requestEnd = true
        })
    }
  }
  // 选择街道/乡镇
  private selecttedTownship() {
    this.infoStatus = false
  }
  // 改变村组
  private changeVillage() {
    this.infoStatus = false
  }
  // 搜索人员列表
  private handleSearch() {
    this.getList()
  }
  // 全选搜索结果
  private handleAll() {
    if (!this.area.township) {
      return this.$message.error('全选操作请选择筛选条件，至少到街道/乡镇！')
    }
    if (!this.infoStatus) {
      return this.$message.error('请先搜索，再进行全选操作！')
    }
    let params = {
      ...this.area,
      userId: this.formInfo.pid
    }
    Object.keys(params).forEach((key) => {
      params[key] && (key == 'province' || key == 'city' || key == 'county' || key == 'township') && (params[key] = params[key].split(',')[1])
      !params[key] && delete params[key]
    })
    this.show = false
    this.loading3 = true
    creditBeAssessorAdd(params)
      .then((res: any) => {
        if (res.data.data) {
          let data = JSON.parse(JSON.stringify(this.addData))
          data = data.concat(res.data.data)
          let obj: any = {},
            addData: any = []
          // this.addData = Array.from(new Set(data))
          for (let i = 0; i < data.length; i++) {
            if (!obj[data[i].id]) {
              addData.push(data[i])
              obj[data[i].id] = true
            }
          }
          if (this.$route.params.id == 'add') {
            this.addData = addData
            this.$set(this.page, 'total', addData.length)
          } else {
            let params: any = {},
              addId: any = addData.map((item: any) => item.farmerId)
            params.beAssessors = addId
            params.pid = this.formInfo.pid
            creditAssessorEdit(params)
              .then((res) => {
                this.$message.success('添加成功')
                this.getDetail(true)
              })
              .catch(() => {
                this.loading3 = false
              })
          }
        } else {
          this.$message.error('添加被评定人失败')
        }
      })
      .finally(() => {
        this.$route.params.id == 'add' && (this.loading3 = false)
      })
  }
  // 获取表格列表
  private getList() {
    this.loading2 = true
    const params = {
      ...this.area,
      pageNumber: this.paginationParam.pageIndex,
      pageSize: this.paginationParam.pageSize,
      userId: this.formInfo.pid
    }
    Object.keys(params).forEach((key) => {
      params[key] && (key == 'province' || key == 'city' || key == 'county' || key == 'township') && (params[key] = params[key].split(',')[1])
      !params[key] && delete params[key]
    })
    userBeAssessorList(params)
      .then((res) => {
        if (res.data.code == 1000) {
          this.tableData = res.data.data
          this.paginationParam.total = res.data.total
          this.infoStatus = true
        }
      })
      .finally(() => {
        this.loading2 = false
      })
  }
  // 选择被评定人
  private handleSelectionChange(val: any) {
    this.row = val
  }
  // 添加被评定人
  private handleAddPerson() {
    if (this.row.length < 1) {
      return this.$message.error('请添加被评定人')
    }
    this.show = false
    this.loading3 = true
    if (this.$route.params.id == 'add') {
      let data = this.addData.concat(this.row)
      let obj: any = {}
      let res: any = []
      // this.addData = Array.from(new Set(data))
      for (let i = 0; i < data.length; i++) {
        if (!obj[data[i].id]) {
          res.push(data[i])
          obj[data[i].id] = true
        }
      }
      this.addData = res
      this.$set(this.page, 'total', this.addData.length)
      this.loading3 = false
    } else {
      let params: any = {}
      // let ids = this.addData.map((item: any) => item.farmerId)
      let addId = this.row.map((item: any) => item.farmerId)
      params.beAssessors = addId
      params.pid = this.formInfo.pid
      creditAssessorEdit(params)
        .then((res) => {
          this.$message.success('添加成功')
          this.getDetail(true)
        })
        .catch(() => {
          this.loading3 = false
        })
    }
  }
  // 删除
  private handleDel(index: number, id: any) {
    this.$confirm('确认删除？')
      .then(() => {
        if (this.$route.params.id == 'add') {
          this.addData.splice(this.page.pageIndex * 10 + index - 10, 1)
          this.$set(this.page, 'total', this.addData.length)
        } else {
          this.loading = true
          creditBeAssessorDel({ id: id })
            .then((res) => {
              this.$message.success('删除成功')
              this.getDetail()
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
      .catch(() => {})
  }
  // 返回
  private handleReturn() {
    this.$router.go(-1)
  }
  // 提交
  private handleSubmit() {
    if (!this.formInfo.role) {
      return this.$message.error('请输入人员角色')
    }
    if (!this.formInfo.person) {
      return this.$message.error('请输入评定人')
    }
    if (this.addData.length < 1) {
      return this.$message.error('请添加被评定人')
    }
    this.loading3 = true
    let params: any = {}
    if (this.$route.params.id == 'add') {
      params.beAssessors = this.addData.map((item: any) => item.id)
    } else {
      params.beAssessors = this.addData.map((item: any) => item.farmerId)
    }
    params.pid = this.formInfo.pid
    creditAssessorAdd(params)
      .then((res) => {
        this.$message.success('添加成功')
        setTimeout(() => {
          this.handleReturn()
        }, 500)
      })
      .catch(() => {
        this.loading3 = false
      })
  }
}
